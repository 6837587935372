import React, { Component } from "react";
import Header from "./../components/header";
import Footer from "./../components/footer";
import { getProduct } from "./../services/products";
import { Link } from "react-router-dom";

class ProductDetails extends Component {
  state = { products: getProduct(this.props.match.params.id) };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <section style={{ minHeight: "300px" }}>
          <div className="product-dtl-page">
            <div className="container">
              <div>
                <h1>{this.state.products.name}</h1>
                <p>{this.state.products.title}</p>
                <article>
                  <figure>
                    <img src={this.state.products.image} alt="" />
                  </figure>
                  <div>
                    <h2>{this.state.products.name}</h2>
                    <h3>Approx Price: {this.state.products.price} / Piece</h3>
                    <p>{this.state.products.desc}</p>
                    <h3>Features:</h3>
                    <p>{this.state.products.features}</p>
                    <h3>Applications:</h3>
                    <p>{this.state.products.applications}</p>
                    <h3>Our range is available in the following grades:</h3>
                    <p>{this.state.products.range}</p>
                    <h3>Available in various sizes:</h3>
                    <p>{this.state.products.sizes}</p>
                    <Link to="/contact">Yes! I am Interested</Link>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default ProductDetails;
