const products = [
  {
    _id: "1",
    name: "Stainless Steel T Port Valve Ball",
    image: require("../assets/images/ss-t-port-valve-ball-250x250.jpg"),
    title:
      "Leading Manufacturer of Stainless Steel T Port Valve Ball from Ahmedabad.",
    price: "Rs 550",
    desc:
      "Our precise and in depth research of the modern day market allows us to offer superior quality Stainless Steel T Port Valve Ball. The offered T Port ball finds extensive usage in high-pressure, heavy duty, high-tolerance applications. At our well equipped manufacturing unit, our provided ball is manufactured with the use of qualitative stainless steel and cutting edge technology. Post production, our offered ball is duly tested on different parameters of quality by our quality examiners.",
    features:
      "Easy maintenance ,Three hole at 90o C Resistance to corrosion, High vacuum to high pressure can be applied, Easy to operate, rapid opening and closing Excellent strength, High performance Effective CostDurable.",
    applications:
      "Industrial applications Apply for two lines from one location Process control applications Applications related to food, dairy, beverage, cosmetic, and pharmaceutical industriesMachinery and equipment.",
    range:
      "S.S. 202, S.S. 304, S.S. 304, LS.S. 316, S.S. 316, LS.S. 420, S.S. 410, EN-32316 , TI Titanium .",
    sizes: "3/8' to 10' in sphere diameter.",
    displayon: "home"
  },
  {
    _id: "2",
    name: "Stainless Steel L Port Valve Ball",
    image: require("../assets/images/ss-t-port-valve-ball-250x250.jpg"),
    title: "Our range of products include Stainless Steel L Port Valve Ball.",
    price: "Rs 550",
    desc:
      "Our precise and in depth research of the modern day market allows us to offer superior quality Stainless Steel T Port Valve Ball. The offered T Port ball finds extensive usage in high-pressure, heavy duty, high-tolerance applications. At our well equipped manufacturing unit, our provided ball is manufactured with the use of qualitative stainless steel and cutting edge technology. Post production, our offered ball is duly tested on different parameters of quality by our quality examiners.",
    features:
      "Easy maintenance ,Three hole at 90o C Resistance to corrosion, High vacuum to high pressure can be applied, Easy to operate, rapid opening and closing Excellent strength, High performance Effective CostDurable.",
    applications:
      "Industrial applications Apply for two lines from one location Process control applications Applications related to food, dairy, beverage, cosmetic, and pharmaceutical industriesMachinery and equipment.",
    range:
      "S.S. 202, S.S. 304, S.S. 304, LS.S. 316, S.S. 316, LS.S. 420, S.S. 410, EN-32316 , TI Titanium .",
    sizes: "3/8' to 10' in sphere diameter.",
    displayon: "home"
  },
  {
    _id: "3",
    name: "CI Air Valve Ball",
    image: require("../assets/images/ci-air-valve-ball-250x250.png"),
    title:
      "We are a leading Manufacturer of CI Air Valve Ball from Ahmedabad, India.",
    displayon: "home"
  },
  {
    _id: "4",
    name: "L Port Ball Valve Ball",
    image: require("../assets/images/l-port-ball-valve-ball-250x250.jpg"),
    title:
      "Providing you the best range of L Port Ball Valve Ball with effective & timely delivery.",
    displayon: "home"
  },
  {
    _id: "5",
    name: "Stem for Ball Valve",
    image: require("../assets/images/stem-for-ball-valve-250x250.jpg"),
    title: "Pioneers in the industry, we offer Stem for Ball Valve from India.",
    displayon: "home"
  },
  {
    _id: "6",
    name: "Three Way Ball",
    image: require("../assets/images/product-250x250.jpeg"),
    title:
      "Providing you the best range of Three Way Valve Ball with effective & timely delivery."
  },
  {
    _id: "7",
    name: "Ball Valve Balls",
    image: require("../assets/images/ball-valve-balls-250x250.jpg"),
    title: "Pioneers in the industry, we offer Ball Valve Balls from India."
  },
  {
    _id: "8",
    name: "L Port Valve Balls",
    image: require("../assets/images/product-250x250.jpeg"),
    title: "Our product range includes a wide range of L Port Valve Balls."
  },
  {
    _id: "9",
    name: "Ball Valve Stem",
    image: require("../assets/images/valve-ball-stam-250x250.jpg"),
    title:
      "Offering you a complete choice of products which include Ball Valve Stem."
  },
  {
    _id: "10",
    name: "Three Way Ball Valve Balls",
    image: require("../assets/images/three-way-ball-valve-balls-250x250.jpg"),
    title:
      "We are a leading Manufacturer of Three Way Ball Valve Balls from Ahmedabad, India."
  },
  {
    _id: "11",
    name: "T Port Valve Ball",
    image: require("../assets/images/t-port-valve-ball-250x250.jpg"),
    title:
      "Providing you the best range of T Port Valve Ball with effective & timely delivery."
  },
  {
    _id: "12",
    name: "Valve Balls Stem",
    image: require("../assets/images/balls-stam-250x250.jpg"),
    title: "Our product range includes a wide range of Valve Balls Stem."
  },
  {
    _id: "13",
    name: "Extended Spindle",
    image: require("../assets/images/extended-spindle-250x250.jpg"),
    title: "Pioneers in the industry, we offer Extended Spindle from India."
  },
  {
    _id: "14",
    name: "Balls Stem",
    image: require("../assets/images/balls-stam-250x250.jpg"),
    title:
      "Providing you the best range of Balls Stem with effective & timely delivery."
  },
  {
    _id: "15",
    name: "Globe Valve Parts",
    image: require("../assets/images/globe-valve-parts-250x250.jpg"),
    title: "Leading Manufacturer of Globe Valve Parts from Ahmedabad."
  },
  {
    _id: "16",
    name: "IC Holo Balls",
    image: require("../assets/images/ic-holo-balls-250x250.jpg"),
    title:
      "Offering you a complete choice of products which include IC Holo Balls."
  },
  {
    _id: "17",
    name: "Stainless Steel Four Way Valve Ball",
    image: require("../assets/images/ss-4-way-valve-ball-250x250.jpg"),
    title:
      "Providing you the best range of Stainless Steel Four Way Valve Ball."
  },
  {
    _id: "18",
    name: "Stainless Steel Balls",
    image: require("../assets/images/stainless-steel-balls-250x250.jpg"),
    title:
      "Manufacturer of a wide range of products which include Stainless Steel Balls."
  },
  {
    _id: "19",
    name: "Stainless Steel Hollow Balls",
    image: require("../assets/images/stainless-steel-hollow-balls-250x250.jpg"),
    title:
      "Pioneers in the industry, we offer Stainless Steel Hollow Balls from India."
  },
  {
    _id: "20",
    name: "Steel Balls",
    image: require("../assets/images/steel-balls-250x250.jpg"),
    title: "Our range of products include Steel Balls.",
    displayon: "home"
  },
  {
    _id: "21",
    name: "Precision Balls",
    image: require("../assets/images/precision-balls-250x250.jpg"),
    title: "Our product range includes a wide range of Precision Balls.",
    displayon: "home"
  },
  {
    _id: "22",
    name: "Chrome Steel Balls",
    image: require("../assets/images/chrome-steel-balls-250x250.jpg"),
    title:
      "We are a leading Manufacturer of Chrome Steel Balls from Ahmedabad, India."
  },
  {
    _id: "23",
    name: "Mild Steel Balls",
    image: require("../assets/images/mild-steel-balls-250x250.jpg"),
    title:
      "Prominent & Leading Manufacturer from Ahmedabad, we offer Mild Steel Balls.",
    displayon: "home"
  },
  {
    _id: "24",
    name: "Chromium Steel Ball",
    image: require("../assets/images/chromium-steel-ball-250x250.jpg"),
    title: "Leading Manufacturer of Chromium Steel Ball from Ahmedabad."
  },
  {
    _id: "25",
    name: "Carbon Steel Balls",
    image: require("../assets/images/carbon-steel-balls-250x250.jpg"),
    title: "Pioneers in the industry, we offer Carbon Steel Balls from India."
  },
  {
    _id: "26",
    name: "High Carbon Steel Balls",
    image: require("../assets/images/high-carbon-steel-balls-250x250.jpg"),
    title:
      "Offering you a complete choice of products which include High Carbon Steel Balls."
  }
];

export function getProducts() {
  return products;
}

export function getProduct(id) {
  return products.find(pd => pd._id === id);
}
