import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import "./index.css";
import "./assets/css/style.css";
import "./assets/css/font-awesome.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
serviceWorker.unregister();

ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById("root")
);
