import React, { Component } from "react";
import Header from "../components/header";
import Footer from "./../components/footer";
import { Link } from "react-router-dom";
import { getProducts } from "./../services/products";

class Home extends Component {
  state = { products: getProducts() };

  componentDidMount() {
    window.scrollTo(0, 0);
    const products = this.state.products.filter(pd => pd.displayon === "home");
    this.setState({ products });
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <section style={{ minHeight: "500px" }}>
          <div className="home-company">
            <div className="container">
              <div className="home-company-bg">
                <h2>Company Factsheet</h2>
                <ul>
                  <li>
                    <figure>
                      <span>&nbsp;</span>
                    </figure>
                    <article>
                      <h3>Nature of Business</h3>
                      <h4>Manufacturer</h4>
                    </article>
                  </li>

                  <li>
                    <figure>
                      <span>&nbsp;</span>
                    </figure>
                    <article>
                      <h3>ISO 9001:2015</h3>
                      <h4>Certified by EIACI</h4>
                    </article>
                  </li>

                  <li>
                    <figure>
                      <span>&nbsp;</span>
                    </figure>
                    <article>
                      <h3>Year of Establishment</h3>
                      <h4>2010</h4>
                    </article>
                  </li>

                  <li>
                    <figure>
                      <span>&nbsp;</span>
                    </figure>
                    <article>
                      <h3>Legal Status of Firm</h3>
                      <h4>Proprietorship Firm</h4>
                    </article>
                  </li>

                  <li>
                    <figure>
                      <span>&nbsp;</span>
                    </figure>
                    <article>
                      <h3>B2B Customers</h3>
                      <h4>More than 1000+ </h4>
                    </article>
                  </li>

                  <li>
                    <figure>
                      <span>&nbsp;</span>
                    </figure>
                    <article>
                      <h3>GST No.</h3>
                      <h4>24ARNPP7754N1ZW</h4>
                    </article>
                  </li>
                </ul>
                <div className="company-highlight">
                 High grade stainless steel regular valve balls, S S L port valve balls, and S S 4 way valve balls are among the products we manufacture and supply. The offered goods are renowned for their endurance and impeccable finish standards.
                  <br />
                  <Link to="/about">Know More</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="home-products">
            <div className="container">
              <div>
                <h2>Stainless Steel Valve Balls</h2>
                <ul>
                  {this.state.products.map(products => (
                    <li key={products._id}>
                      <article>
                        <figure>
                          <img src={products.image} alt="" />
                        </figure>
                        <div>
                          <h3>{products.name}</h3>
                          <p>{products.title}</p>
                          <Link to={`/product-details/${products._id}`}>
                            Know More
                          </Link>
                        </div>
                      </article>
                    </li>
                  ))}
                </ul>
                <Link className="view-catelog" to="/products">
                  View All Categories
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
