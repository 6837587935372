import React, { Component } from "react";
import Header from "./../components/header";
import Footer from "./../components/footer";
import { Link } from "react-router-dom";

class Contact extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <section style={{ minHeight: "300px" }}>
          <div className="contact-us">
            <div className="container">
              <div>
                <h1>Contact Us</h1>
				<p>If you are interested in our company or products, welcome to visit our company or local offices; you can also get contact with us through online consulting, , e-mails and telephones. Our staff shall wholeheartedly provide product information, application knowledge and good service for you.</p>
                <div className="contact-info">
                  <b>Anand Industries</b>
                  <br />
                  <span>
					LS No 1852 Paiki Plot No. 01, Arihant Industries Estate, Nr Jay Tripati Steel Pvt. Ltd., Santej - Khatraj Road, Ssantej, Tehsil/Taluka - Kalol Dist. Gandhinagar - 382721 (Gujarat) India.
                  </span>
                  <div className="footer-sprt" />
                  <ul>
				    <li className="phone">
                      <i className="fa fa-phone" aria-hidden="true" />
                      <h3>+91 99133 45803</h3>
                    </li>
                    <li className="sms">
                      <i className="fa fa-whatsapp" aria-hidden="true" />
                      <h3>+91 78782 02304</h3>
                    </li>
                    <li className="email">
                      <i className="fa fa-envelope" aria-hidden="true" />
                      <h3>sales@anandindustriesindia.com</h3>
                    </li>
					<li className="skype">
                      <i className="fa fa-skype" aria-hidden="true" />
                      <h3>anandindustriesindia</h3>
                    </li>
                  </ul>
                  <div className="footer-sprt" />
                  <ul className="social-icons">
                    <li>
                      <h3>Follow Us:</h3>
                      <Link to="/">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </Link>
                      <Link to="/">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </Link>
                      <Link to="/">
                        <i className="fa fa-linkedin" aria-hidden="true" />
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="contact-form">
                  <div>
                    <label>Name *</label>
                    <input type="text" />
                  </div>
                  <div>
                    <label>Mobile Number *</label>
                    <input type="text" />
                  </div>
                  <div>
                    <label>Email *</label>
                    <input type="text" />
                  </div>
                  <div>
                    <label>Requirement Details *</label>
                    <textarea />
                  </div>
                  <button>Submit Inquiry</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Contact;
