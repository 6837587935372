import React, { Component } from "react";
import Header from "./../components/header";
import Footer from "./../components/footer";

class About extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <section style={{ minHeight: "300px" }}>
          <div className="about-us">
            <div className="container">
              <div>
                <h1>About Anand Industries</h1>
                <p>
                  Anand Industries., established in 2010, the company have more than 8 years of remarkable experience in producing high quality Valve Balls, Stems, Extended Spindle, Globe Valve Parts, etc for domestic and gloabl valve industries as well as consumer industries of ball valve like chemical industries, etc. Anand Industries also customize products according to drawning from customers which make them quality product suppliers to many medium to large manufacturing industries. We maintain  confidentiality for industrial client.
                </p>
                <p>
                  In order to manufacture these balls with flawlessness and high speed, we have setup a spacious and well-equipped
                  infrastructure base unit. This unit is divided into different sub-functional units for balanced and manageable work
                  performance. In these units, we have install all type of necessary and highly developed manufacturing, quality testing,
                  packaging, finishing and other allied machines. Apart from this, we also have a separate quality testing unit to omit the
                  flaws from our products in quick time for safe and effective working. Our entity is also interlinked with a huge and
                  widespread transportation network that makes it possible for
                  us to deliver these products in safe and timely manner.
                </p>
				<p>
				The presistence, specialization approach makes a well-equipped and well-managed company. It has been certified <b>ISO 9001-2015</b> by Emirates International Accreditation Center (EIAC).
				</p>
                <ul>
                  <li>
                    <h2>Company Factsheet</h2>
                  </li>
                  <li>
                    <h3>Basic Information</h3>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <span>Nature of Business</span>
                        <b>Manufacturer</b>
                      </li>
                      <li>
                        <span>Additional Business</span>
                        <b>Exporter, Wholesaler</b>
                      </li>
                      <li>
                        <span>Company CEO</span>
                        <b>Mr. Nitin Panchal</b>
                      </li>
                      <li>
                        <span>Registered Address</span>
                        <b>
                         LS No 1852 Paiki Plot No. 01, Arihant Industries Estate, Nr Jay Tripati Steel Pvt. Ltd., Santej - Khatraj Road, Ssantej, Tehsil/Taluka - Kalol Dist. Gandhinagar - 382721 (Gujarat) India.
                        </b>
                      </li>
                      <li>
                        <span>Industry</span>
                        <b>
                          Manufacturing and Trading of SS Regular Valve Ball, SS
                          T Port Valve Ball, SS L Port Valve Ball and SS 4 Way
                          Valve Ball.
                        </b>
                      </li>
                      <li>
                        <span>Compliance</span>
                        <b>ISO 9001:2015</b>
                      </li>
                      <li>
                        <span>Year of Establishment</span>
                        <b>2010</b>
                      </li>
                      <li>
                        <span>Legal Status of Firm</span>
                        <b>Proprietorship Firm</b>
                      </li>
                      <li>
                        <span>Promoter</span>
                        <b>Mr. Nitin Panchal</b>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h3>Infrastructure</h3>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <span>Location Type</span>
                        <b>URBAN</b>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h3>Company USP</h3>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <span>Primary Competitive Advantage</span>
                        <b>
                          Good Financial Position & TQM, Large Production
                          Capacity, Provide Customized Solutions, Large Product
                          Line
                        </b>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h3>Statutory Profile</h3>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <span>Banker</span>
                        <b>HDFC Bank Ltd.</b>
                      </li>
                      <li>
                        <span>GST No.</span>
                        <b>24ARNPP7754N1ZW</b>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h3>Packaging/Payment and Shipment Details</h3>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <span>Payment Mode</span>
                        <b>Cheque, DD, Cash, Credit Card</b>
                      </li>
                      <li>
                        <span>Shipment Mode</span>
                        <b>By Road</b>
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul>
                  <li>
                    <h2>Quality Assurance</h2>
                  </li>
                  <li>
                    <p>
                      Quality consistency and client satisfaction remains
                      foremost preference for our organization. The offered
                      products are stringently checked on different quality
                      parameters as per the set industry norms by our highly
                      talented quality controllers. Moreover, our quality
                      inspectors strictly check each and every product on
                      different quality parameters using modular testing tools
                      and equipments assuring their durability and finish at
                      user’s end.
                    </p>
                  </li>
                </ul>

                <ul>
                  <li>
                    <h2>Our Team</h2>
                  </li>
                  <li>
                    <p>
                      Our team of trained and experienced professionals is our
                      greatest strength and biggest differentiator. These
                      professionals are recruited on the bases of their
                      qualification, quick learning ability and practical
                      business experience. Their credibility in their respective
                      field is examined by our operation managers after
                      conducting various rounds of interviews and group
                      discussion. In addition to this, we also provide special
                      training sessions and seminars in between to enhance their
                      knowledge and expertise as per the rules and regulation of
                      our industry.
                    </p>
                  </li>
                </ul>

                <ul>
                  <li>
                    <h2>Why Us?</h2>
                  </li>
                  <li>
                    <p>
                      We are successful in attaining a remarkable position in
                      the market due to our business oriented policies and
                      transference in dealing.
                    </p>
                  </li>
                  <li>
                    <b>
                      Other factors behind our success in this domain are as
                      follows:
                      <br />
                      <br />
                    </b>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <span>Highly experienced professionals</span>
                      </li>
                      <li>
                        <span>Stringent quality standards</span>
                      </li>
                      <li>
                        <span>Client-centric approach</span>
                      </li>
                      <li>
                        <span>High-tech manufacturing facilities</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default About;
