import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./screens/home";
import About from "./screens/about";
import Products from "./screens/products";
import ProductDetails from "./screens/product-details";
import Contact from "./screens/contact";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/products" component={Products} />
        <Route path="/product-details/:id" component={ProductDetails} />
        <Route path="/contact" component={Contact} />
      </Switch>
    );
  }
}

export default App;
