import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer>
        <div className="container">
          <div>
            <b>Anand Industries</b>
            <br />
            <span>
              LS No 1852 Paiki Plot No. 01, Arihant Industries Estate, Nr Jay Tripati Steel Pvt. Ltd., Santej - Khatraj Road, Ssantej, Tehsil/Taluka - Kalol Dist. Gandhinagar - 382721 (Gujarat) India.
            </span>
            <div className="footer-sprt" />
            <ul>
              <li className="phone">
                <i className="fa fa-phone" aria-hidden="true" />
                <h3>+91 99133 45803</h3>
              </li>
              <li className="sms">
                <i className="fa fa-whatsapp" aria-hidden="true" />
                <h3>+91 78782 02304</h3>
              </li>
              <li className="email">
                <i className="fa fa-envelope" aria-hidden="true" />
                <h3>sales@anandindustriesindia.com</h3>
              </li>
			  <li className="Skype">
                <i className="fa fa-skype" aria-hidden="true" />
                <h3>anandindustriesindia</h3>
              </li>
            </ul>
            <div className="footer-sprt" />
            <ul className="social-icons">
              <li>
                <h3>Follow Us</h3>
                <Link to="/">
                  <i className="fa fa-facebook" aria-hidden="true" />
                </Link>
                <Link to="/">
                  <i className="fa fa-twitter" aria-hidden="true" />
                </Link>
                <Link to="/">
                  <i className="fa fa-linkedin" aria-hidden="true" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="copywrite">
            <p>© Anand Industries. All Rights Reserved (Terms of Use)</p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
