import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <header>
        <div className="container">
          <Link to="/" className="logo">
            <img
              src={require("../assets/images/AnandIndustries_logo.png")}
              alt=""
            />
          </Link>
          <ul className="social-icons">
            <li>
              <Link to="/">
                <i className="fa fa-phone" aria-hidden="true" />
              </Link>
              <Link to="/">
                <i className="fa fa-envelope" aria-hidden="true" />
              </Link>
              <Link to="/">
                <i className="fa fa-facebook" aria-hidden="true" />
              </Link>
              <Link to="/">
                <i className="fa fa-twitter" aria-hidden="true" />
              </Link>
              <Link to="/">
                <i className="fa fa-linkedin" aria-hidden="true" />
              </Link>
            </li>
          </ul>
        </div>

        <nav>
          <div className="container">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
