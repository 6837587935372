import React, { Component } from "react";
import Header from "./../components/header";
import Footer from "./../components/footer";
import { Link } from "react-router-dom";
import { getProducts } from "./../services/products";

class Products extends Component {
  state = { products: getProducts() };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <section style={{ minHeight: "300px" }}>
          <div className="products-page">
            <div className="container">
              <div>
                <h1>Stainless Steel Valve Balls</h1>
                <p>
                  We are the dynamic, business-oriented organization, ranking
                  among the utmost entities in manufacturing and supplying
                  enhanced quality SS Regular Valve Ball, SS T Port Valve Ball,
                  SS L Port Valve Ball and SS 4 Way Valve Ball. The offered
                  balls are manufactured using high grade stainless steel
                  material and contemporary machines under the visionary
                  guidance of trained professionals. Further, these are checked
                  by our quality analysts assuring their flawlessness at user’s
                  end.
                </p>
                <h2>We offer:</h2>
                <ul>
                  {this.state.products.map(products => (
                    <li key={products._id}>
                      <article>
                        <figure>
                          <img src={products.image} alt="" />
                        </figure>
                        <div>
                          <h3>{products.name}</h3>
                          <p>{products.title}</p>
                          <Link to={`/product-details/${products._id}`}>
                            Know More
                          </Link>
                        </div>
                      </article>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Products;
